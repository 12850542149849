import { useSectionEffectsContext } from '@ecp-boxes/structure/Contexts/SectionEffectsContext';
import { ILinkContent, SelectedGoToOption, TRowId } from '@ecp-boxes/settingsPatterns/contentPatterns.types';
import { PathNumberType } from '../portalPath/portalPath';

import { useWindowScrollPositions } from './useWindowScrollPosition';
import { useCallback } from 'react';

const useIsActiveSectionButton = (elementParentId: PathNumberType) => {
  const { effectsData } = useSectionEffectsContext();

  const { scrollY: scrollPositionY } = useWindowScrollPositions();

  const getSection = (rowId: TRowId) => effectsData.find(
    (el) => `${el.sectionId}` === rowId
  )

  const getIsActiveByData = useCallback((interSectingElementLink: ILinkContent): boolean => {
    if (interSectingElementLink.selectedGoToOption !== SelectedGoToOption.GO_TO_ROW) return false

    const sectionRef = getSection(interSectingElementLink.goToRowSetting.rowId)
    const sectionDimensions = sectionRef?.ref?.getBoundingClientRect()

    if (sectionDimensions === undefined) return false

    const parentElementIdx = effectsData.findIndex(
      (el) => el.sectionId === elementParentId
    )
    const parentElement = effectsData[parentElementIdx]
    if (parentElement === undefined || parentElement.height === undefined || parentElement.offset === undefined) return false

    const observingElementIdx = effectsData.findIndex(
      (el) => el.sectionId?.toString() === interSectingElementLink.goToRowSetting.rowId?.toString()
    )

    const spaceToLastSticky = [...effectsData]
      .splice(parentElementIdx + 1, observingElementIdx - parentElementIdx)
      .filter((el) => el.sticky)
      .reduce((acc, el) => acc + (el.height ?? 0), 0);

    const buttonBottomPositionWithSticky = parentElement.height + parentElement.offset + spaceToLastSticky;

    return buttonBottomPositionWithSticky >= sectionDimensions.top && buttonBottomPositionWithSticky <= sectionDimensions.bottom

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effectsData, elementParentId, scrollPositionY])

  return { getIsActiveByData };
}

export default useIsActiveSectionButton;