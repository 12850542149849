import React, {
  MutableRefObject,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
} from "react";
import { nanoid } from "nanoid";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import DropdownPortal from "../../../shared/components/Dropdown/DropdownPortal";
import useDropdownDimensions from "../../../shared/components/Dropdown/useDropdownDimensions";
import { useLayoutControllerContext } from "../../../structure/Contexts/LayoutControllerContext";
import { StyledMultimenuHorizontalDropdownContent } from "./MultimenuHorizontalDropdown.styled";

interface IMultiMenuHorizontalDropdownProps {
  width: number;
  isFullWidth: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  buttonContent: ReactNode;
  selectContainerContent: ReactNode;
  menuWrapperPercentageWidth: number;
  closeDropdown?: () => void;
  openHorizontalDirection: AlignmentHorizontalOption;
  onBackDropClick?: () => void;
  onBackDropMouseOver?: () => void;
  multimenuFullWidthPortalRef: MutableRefObject<HTMLUListElement | null>;
  publicationRef?: RefObject<HTMLDivElement>;
}

const MultimenuHorizontalDropdown: React.FC<
  IMultiMenuHorizontalDropdownProps
> = ({
  width,
  isFullWidth,
  isOpen,
  setIsOpen,
  buttonContent,
  selectContainerContent,
  menuWrapperPercentageWidth,
  closeDropdown,
  openHorizontalDirection,
  onBackDropClick,
  onBackDropMouseOver,
  multimenuFullWidthPortalRef,
  publicationRef,
}) => {
  const dropdownWrapper = useRef<HTMLDivElement>(null);
  const dropdownContent = useRef<HTMLDivElement>(null);

  const { dropdownContentDimensions, dropdownOpen } = useDropdownDimensions(
    isOpen,
    isFullWidth && multimenuFullWidthPortalRef
      ? multimenuFullWidthPortalRef
      : dropdownWrapper,
    dropdownContent
  );

  const left = publicationRef?.current
    ? (publicationRef.current.closest("#layout-width")?.getBoundingClientRect()
        .left ?? 0)
    : 0;

  const { lastClickedId, setLastClickedId } = useLayoutControllerContext();
  const dropdownId = nanoid();

  const handleMouseEnter = () => {
    setLastClickedId(dropdownId);
  };

  useEffect(() => {
    if (lastClickedId !== dropdownId && dropdownOpen) {
      setIsOpen(false);
    }
  }, [lastClickedId]);

  useEffect(() => {
    const handleWheel = () => {
      setIsOpen(false);
    };

    dropdownWrapper.current?.addEventListener("wheel", handleWheel, {
      passive: true,
    });

    return () => {
      dropdownWrapper.current?.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <div ref={dropdownWrapper}>
      <div
        onClick={() => setLastClickedId(dropdownId)}
        onMouseEnter={handleMouseEnter}
      >
        {buttonContent}
      </div>
      <DropdownPortal
        isOpen={dropdownOpen}
        close={closeDropdown}
        onBackDropClick={() => {
          onBackDropClick && onBackDropClick();
          setLastClickedId(null);
        }}
        onBackDropMouseOver={onBackDropMouseOver}
        data-testid="dropdown"
      >
        <StyledMultimenuHorizontalDropdownContent
          ref={dropdownContent}
          openHorizontalDirection={openHorizontalDirection}
          left={isFullWidth ? left : (dropdownContentDimensions?.left ?? null)}
          top={dropdownContentDimensions?.top ?? null}
          right={dropdownContentDimensions?.right ?? null}
          wrapperWidth={dropdownContentDimensions?.wrapperWidth ?? null}
          menuWrapperPercentageWidth={menuWrapperPercentageWidth}
          dropdownWidth={width}
        >
          {selectContainerContent}
        </StyledMultimenuHorizontalDropdownContent>
      </DropdownPortal>
    </div>
  );
};

export default MultimenuHorizontalDropdown;
